import http from "../http-common";


const get = () => {
    return http.get('/resource/get-logic-apps')
}

const JobsService = {
    get
}

export default JobsService;