import { Content, Header, Page } from '@backstage/core-components';
// import { CardContent, Grid } from '@material-ui/core';
import React, { } from 'react';
// import SettingsCard from './SettingsCard';
// import { IRequestData } from '../../types/Requests';

// const datas = new Array<IRequestData>();

// datas.push({
//     title: 'Solicitação de nova funcionalidade',
//     description: 'Olá! Obrigado por compartilhar suas ideias conosco. Pedimos que preencha esse formulário para nos ajudar a entender melhor suas ideias.',
//     link: 'https://forms.clickup.com/3096194/f/2yfm2-26303/ERV768QO3A64NDOCXM'
// });

// datas.push({
//     title: 'Solicitação de nova funcionalidade',
//     description: 'Olá! Obrigado por compartilhar suas ideias conosco. Pedimos que preencha esse formulário para nos ajudar a entender melhor suas ideias.',
//     link: 'https://forms.clickup.com/3096194/f/2yfm2-26303/ERV768QO3A64NDOCXM'
// });

const SettingsPage = () => {
    return (
        React.createElement(Page, { themeId: "home",}
            , React.createElement(Header, { title: "Requests", subtitle: "Solicitações da General Claims"   ,})
            , React.createElement(Content, null
                /* <Wrapper>
                    {
                        datas.map((data) => {
                            return (
                                <Grid item xs={10}>
                                    <SettingsCard
                                        title={data.title}
                                        description={data.description}
                                        link={data.link}
                                    ></SettingsCard>
                                </Grid>
                            )
                        })
                    }
                </Wrapper> */
                /* <CardContent> */
                    , React.createElement('iframe', { src: "https://forms.clickup.com/3096194/f/2yfm2-26303/ERV768QO3A64NDOCXM", width: "100%", height: "100%",}), React.createElement('script', { async: true, src: "https://app-cdn.clickup.com/assets/js/forms-embed/v1.js",})
                /* </CardContent> */
            )
        )
    )
}

// const Wrapper = ({ children }: PropsWithChildren<{}>) => (
//     <Grid container spacing={4}>
//         {children}
//     </Grid>
// );

export default (
    React.createElement(SettingsPage, null)
)