import React from 'react';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { TechRadarPage } from '@backstage/plugin-tech-radar';

import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { techDocsPage } from './components/techdocs/TechDocsPage';


import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage, } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import jobsPage from './components/jobs/JobsPage';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { customTheme } from "./Theme";
import ApplicationsPage from './components/applications/ApplicationsPage';
import { SearchPage } from '@backstage/plugin-search';
import SettingsPage from './components/settings/SettingsPage';


const microsoftAuthProvider = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message: 'Sign in to Backstage Application using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  themes: [{
    id: 'custom-theme',
    title: 'General Claims Theme',
    variant: 'light',
    Provider: ({ children }) => (
      React.createElement(ThemeProvider, { theme: customTheme,}
        , React.createElement(CssBaseline, null, children)
      )
    ),
  },
  ],
  components: {
    SignInPage: props => (
      React.createElement(SignInPage, {
        ...props,
        provider: microsoftAuthProvider,}
      )
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      // createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Navigate, { key: "/", to: "tech-radar",} )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
    , React.createElement(Route, { path: "/jobs", element: React.createElement(CatalogIndexPage, { initialKind: "api",} ),} , " " , jobsPage, " " )
    , React.createElement(Route, { path: "/applications", element: React.createElement(CatalogIndexPage, { initialKind: "api",} ),} , " " , ApplicationsPage, " " )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, { initialKind: "group",} ),} )

    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}
    
      , entityPage
    )

    , React.createElement(Route, { path: "/docs", element: React.createElement(TechDocsIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/docs/:namespace/:kind/:name/*",
      element: React.createElement(TechDocsReaderPage, null ),}
    
      , techDocsPage
      , React.createElement(TechDocsAddons, null
        , React.createElement(ExpandableNavigation, null )
        , React.createElement(ReportIssue, null )
        , React.createElement(TextSize, null )
      )
    )


    , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: React.createElement(TechRadarPage, { width: 1500, height: 800,  title: "General Claims" , subtitle: "Radar Tech" ,  pageTitle: "About Us" ,} ),}
    )

    , React.createElement(PermissionedRoute, {
      path: "/catalog-import",
      permission: catalogEntityCreatePermission,
      element: React.createElement(CatalogImportPage, null ),}
    )

    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )

    , React.createElement(Route, { path: "/request",}, SettingsPage)

    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} 
      /* <SettingsCard></SettingsCard> */
    )
    , React.createElement(Route, { path: "/catalog-graph", element: React.createElement(CatalogGraphPage, null ),} )
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;
