import React, { Component, } from 'react';
import JobsService from '../../services/JobsService';
import JobsCard from './JobsCard';
import { Page, Header, Content, Progress } from '@backstage/core-components'
import { Grid } from '@material-ui/core';





class JobsPage extends Component {
    interval = 0;
    constructor(props) {
        super(props);
        this.state = { jobs: [] }
    }

    componentDidMount() {
        this.getJobs();
        this.interval = setInterval(() => {
            this.getJobs();
        }, 6e4)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getJobs() {
        JobsService.get()
            .then(res => {
                this.setState({
                    jobs: res.data.sort((a, b) => a.isLate === b.isLate ? 0 : a.isLate ? -1 : 1)
                });
                // this.jobs = res.data.sort((a, b) => a.isLate === b.isLate ? 0 : a.isLate ? -1 : 1)
            }).catch((e) => {
                console.debug({ ...e })
                throw e.message;
            })
    }

    render() {
        if (this.state.jobs.length == 0) {
            return React.createElement(Progress, null )
        }
        return (
            React.createElement(Page, { themeId: "home",}
                , React.createElement(Header, { title: "Jobs", subtitle: "Rotinas de trabalhos da General Claims"     ,} )
                /* <Title title='Jobs'></Title> */
                , React.createElement(Content, null
                    , React.createElement(Wrapper, null
                        , 
                            this.state.jobs.map((data, index) => {
                                return (
                                    React.createElement(Grid, { item: true, xs: 3,}
                                        , React.createElement(JobsCard, {
                                            description: data.description,
                                            name: data.name,
                                            endTime: data.endTime,
                                            isLate: data.isLate,
                                            lastStatus: data.lastStatus,
                                            project: data.project,
                                            rg: data.rg,
                                            startTime: data.startTime,
                                            status: data.status,
                                            subscription: data.subscription,
                                            timer: data.timer,
                                            squad: data.squad,
                                            key: index + 'job',}
                                        )
                                    )
                                )
                            })
                        
                    )
                )
            )
        )
    }

}

const Wrapper = ({ children }) => (
    React.createElement(Grid, { container: true, spacing: 3,}
        , children
    )
);

export default (
    React.createElement(JobsPage, null)
)