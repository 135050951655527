import { Card, CardActions, CardContent, Chip, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState } from "react";

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        truncate: {
            width: '250px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }),
);

const IsLate = ({ status }) => {
    if (!status) return null;

    if(status == 200) return React.createElement(Chip, { style: { backgroundColor: 'green', color: 'white' }, label: `${status}`,} )

    return (
        React.createElement(Chip, { style: { backgroundColor: 'red', color: 'white' }, label: `${status}`,} )
    )
}

const ApplicationsCard = ({
    ambiente,
    estado,
    lastStatus,
    name,
    // ok,
    projeto,
    rg,
    subscricao,
    squad
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    // const openDialog = () => {
    //     setOpen(true);
    // };

    const closeDialog = () => {
        setOpen(false);
    };

    const dialogContent = () => {
        return (
            React.createElement(React.Fragment, null
                , React.createElement('div', null
                    , React.createElement('h3', null, "Ambiente: " , React.createElement('span', { style: { fontWeight: 'normal' },}, ambiente))
                    , React.createElement('h3', null, "Squad: " , React.createElement('span', { style: { fontWeight: 'normal' },}, React.createElement(Link, { href: `/catalog/default/group/${squad}`,}, " " , squad)))
                    , React.createElement('h3', null, "Código último status: "   , React.createElement('span', { style: { fontWeight: 'normal' },}, lastStatus))
                    , React.createElement('h3', null, "Estado: " , React.createElement('span', { style: { fontWeight: 'normal' },}, estado))
                    , React.createElement('h3', null, "RG: " , React.createElement('span', { style: { fontWeight: 'normal' },}, rg))
                    , React.createElement('h3', null, "Subscrição: " , React.createElement('span', { style: { fontWeight: 'normal' },}, subscricao))
                )
            )
        );
    };

    return (
        React.createElement(React.Fragment, null
            , React.createElement(Card, null
                , React.createElement(CardContent, null
                    , React.createElement(Chip, { label: projeto,} )
                    , React.createElement(IsLate, { status: lastStatus,})
                    , React.createElement(Tooltip, { title: name,}
                        , React.createElement('p', { className: classes.truncate,}, React.createElement('span', { style: { fontWeight: 'bold' },}, "Nome:"), " " , name)
                    )
                    , React.createElement('p', null, React.createElement('span', { style: { fontWeight: 'bold' },}, "Ambiente:"), " " , ambiente)
                    , React.createElement('p', null, " " , React.createElement('span', { style: { fontWeight: 'bold' },}, "Squad:"), React.createElement(Link, { href: `/catalog/default/group/${squad}`,}, " " , squad))
                    , React.createElement('p', null, React.createElement('span', { style: { fontWeight: 'bold' },}, "Ambiente:"), " " , ambiente)
                    , React.createElement('p', null, React.createElement('span', { style: { fontWeight: 'bold' },}, "Estado:"), " " , estado)
                    , React.createElement('p', null, React.createElement('span', { style: { fontWeight: 'bold' },}, "RG:"), " " , rg)
                    , React.createElement('p', null, React.createElement('span', { style: { fontWeight: 'bold' },}, "Subscrição:"), " " , subscricao)
                )
                , React.createElement(CardActions, null
                    /* <Button color="primary" size="small" onClick={openDialog}>Detalhes</Button> */
                )
            )

            , React.createElement(Dialog, {
                open: open,
                onClose: closeDialog,
                'aria-labelledby': "dialog-title",
                'aria-describedby': "dialog-description",
                maxWidth: "xl",}
            
                , React.createElement(DialogTitle, { id: "dialog-title",}
                    , React.createElement(Chip, { label: projeto,} ), " " , React.createElement('br', null ), name
                    , React.createElement(IconButton, {
                        'aria-label': "close",
                        className: classes.closeButton,
                        onClick: closeDialog,}
                    
                        , React.createElement(CloseIcon, null )
                    )
                )
                , React.createElement(DialogContent, null, dialogContent())
                , React.createElement(DialogActions, null
                )
            )
        )
    )
}

export default (
    ApplicationsCard
)