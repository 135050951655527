import { Button, Card, CardActions, CardContent, Chip, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";

import CloseIcon from '@material-ui/icons/Close';
import { format } from "date-fns";

const useStyles = makeStyles((theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        truncate: {
            width: '250px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }),
);


const IsLate = ({ isLate, status }) => {
    if(status == 'Desativado') return null;
    if (!isLate) return React.createElement(Chip, { style: { backgroundColor: 'green', color: 'white' }, label: "Ok",} );

    return (
        React.createElement(Chip, { style: { backgroundColor: 'red', color: 'white' }, label: "Atrasado",} )
    )
}

const Disabled = ({ status }) => {
    if (status != 'Desativado') return null;

    return (
        React.createElement(Chip, { style: { backgroundColor: 'orange', color: 'white' }, label: status,} )
    )
}

const JobsCard = ({ name, description, endTime, isLate, lastStatus, project, rg, startTime, status, subscription, timer, squad }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const dialogContent = () => {
        return (
            React.createElement(React.Fragment, null
                , React.createElement(IsLate, { isLate: isLate, status: status,})
                , React.createElement(Disabled, { status: status,} )
                , React.createElement(Typography, null
                    , description
                )
                , React.createElement('div', null
                    , React.createElement('h3', null, "Squad: " , React.createElement('span', { style: { fontWeight: 'normal' },}, React.createElement(Link, { href: `/catalog/default/group/${squad}`,}, " " , squad)))
                    , React.createElement('h3', null, "Último status: "  , React.createElement('span', { style: { fontWeight: 'normal' },}, lastStatus == 'Failed' ? 'Fracassado' : 'Sucesso'))
                    , React.createElement('h3', null, "RG: " , React.createElement('span', { style: { fontWeight: 'normal' },}, rg))
                    , React.createElement('h3', null, "Subscrição: " , React.createElement('span', { style: { fontWeight: 'normal' },}, subscription))
                    , React.createElement('h3', null, "Status: " , React.createElement('span', { style: { fontWeight: 'normal' },}, status))
                    , React.createElement('h3', null, "Início: " , React.createElement('span', { style: { fontWeight: 'normal' },}, startTime ? format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss') : ''))
                    , React.createElement('h3', null, "Última execução: "  , React.createElement('span', { style: { fontWeight: 'normal' },}, endTime ? format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss') : ''))
                    , React.createElement('h3', null, "Tempo (minutos): "  , React.createElement('span', { style: { fontWeight: 'normal' },}, timer))
                )
            )
        );
    };

    return (
        React.createElement(React.Fragment, null
            , React.createElement(Card, { style: {minHeight: '250px'},}
                , React.createElement(CardContent, null
                    , React.createElement(Chip, { label: project,} )
                    , React.createElement(IsLate, { isLate: isLate, status: status,})
                    , React.createElement(Disabled, { status: status,} )
                    /* <p>{project}</p> */
                    , React.createElement('p', null, description)
                    , React.createElement(Tooltip, { title: name,}
                        , React.createElement('p', { className: classes.truncate,}, React.createElement('span', { style: { fontWeight: 'bold' },}, "Nome:"), " " , name)
                    )
                    , React.createElement('p', null, " " , React.createElement('span', { style: { fontWeight: 'bold' },}, "Squad:"), React.createElement(Link, { href: `/catalog/default/group/${squad}`,}, " " , squad))
                    , React.createElement('p', null, React.createElement('span', { style: { fontWeight: 'bold' },}, "Última execução:" ), " " , endTime ? format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss') : '')
                )
                , React.createElement(CardActions, null
                    , React.createElement(Button, { color: "primary", size: "small", onClick: openDialog,}, "Detalhes")
                )
            )
            , React.createElement(Dialog, {
                open: open,
                onClose: closeDialog,
                'aria-labelledby': "dialog-title",
                'aria-describedby': "dialog-description",
                maxWidth: "xl",}
            
                , React.createElement(DialogTitle, { id: "dialog-title",}
                    , React.createElement(Chip, { label: project,} ), " " , React.createElement('br', null ), name
                    , React.createElement(IconButton, {
                        'aria-label': "close",
                        className: classes.closeButton,
                        onClick: closeDialog,}
                    
                        , React.createElement(CloseIcon, null )
                    )
                )
                , React.createElement(DialogContent, null, dialogContent())
                , React.createElement(DialogActions, null
                    /* <Button color="primary" onClick={closeDialog}>
                        Secondary action
                    </Button>
                    <Button color="primary" onClick={closeDialog}>
                        Primary action
                    </Button> */
                )
            )

        )

    )
}

export default (
    JobsCard
)