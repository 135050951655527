





 import { mock } from './tech-radar-data';
  
  export class TedhRadarData  {
    async load() {

  
      const data = mock;
  
      return {
        ...data,
        entries: data.entries.map(entry => ({
          ...entry,
          timeline: entry.timeline.map(timeline => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }