







const rings = new Array();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#93c47d' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

const quadrants = new Array();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure And Datastores' });
quadrants.push({ id: 'frameworks', name: 'Frameworks' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'process', name: 'Process' });

const entries = new Array();
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/dotnet/csharp/',
  key: 'C#',
  id: 'C#',
  title: 'C#',
  quadrant: 'languages',
  description:
    'C# é uma linguagem de programação orientada a objetos e orientada a componentes. C# fornece construções de linguagem para dar suporte diretamente a esses conceitos, tornando C# uma linguagem natural para criação e uso de componentes de software. Desde sua origem, o C# adicionou recursos para dar suporte a novas cargas de trabalho e práticas emergentes de design de software. Em sua essência, o C# é uma linguagem orientada a objeto. Você define os tipos e o comportamento deles.\n\n Exemplo de código: \n\n```ts\n' +
    'namespace HelloWorld { \n' +
    '   class Hello { \n' +
    '      static void Main(string[] args) \n' +
    '      { \n' +
    '         System.Console.WriteLine("Hello World!"); \n' +
    '      } \n' +
    '   } \n' +
    '}' + '\n```\n',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://www.javascript.com',
  key: 'javascripts',
  id: 'javascripts',
  title: 'JavaScript',
  quadrant: 'languages',
  description:
    'A linguagem de programação Javascript permite ao desenvolvedor implementar diversos itens de alto nível de complexidade em páginas web, como animações, mapas, gráficos ou informações que se atualizam em intervalos de tempo padrão, por exemplo.Javascript é a terceira camada do bolo de desenvolvimento web e front-end, junto com HTML, CSS e PHP.\n\n Exemplo de código: \n\n```ts\nconsole.log("Hello World");\n```\n',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://www.typescriptlang.org',
  key: 'typescript',
  id: 'typescript',
  title: 'TypeScript',
  quadrant: 'languages',
  description:
    'O TypeScript começou a ser desenvolvido pela Microsoft em 2012, com o objetivo de adicionar recursos e ferramentas que não estão presentes nativamente na linguagem (ou que seriam muito mais complexos de serem implementados), como tipagem estática (ou seja, os tipos das variáveis são definidos explicitamente no código) e orientação a objetos. Por isso, ele não é usualmente considerado como uma nova linguagem de programação, mas sim como um superconjunto de JavaScript, pois o código é “transformado” (no termo técnico: transcompilado) em JavaScript “puro” antes de ser executado. \n\n Exemplo de código: \n\n```ts\nlet message: string = "Hello, World!";\nconsole.log(message);\n```\n',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://webpack.js.org/',
  key: 'webpack',
  id: 'webpack',
  title: 'Webpack',
  quadrant: 'frameworks',
  description:
    'O webpack é um empacotador de módulos estáticos para aplicações JavaScript modernas. Ao processar a aplicação o webpack gera um gráfico que mapeia cada módulo e suas dependências e gera um ou mais pacotes.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://www.figma.com',
  key: 'figma',
  id: 'figma',
  title: 'Figma',
  quadrant: 'frameworks',
  description:
    'Figma é um editor gráfico de vetor e prototipagem de projetos de design baseado principalmente no navegador web, com ferramentas offline adicionais para aplicações desktop para GNU/Linux, macOS e Windows.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://reactjs.org/',
  key: 'react',
  id: 'react',
  title: 'React',
  quadrant: 'frameworks',
  description:
    'O React é a biblioteca mais popular do JavaScript e é usada para construir uma interface de usuário (IU). Ela oferece uma resposta excelente para o usuário adicionar comandos usando um novo método de renderizar sites. Os componentes dessa ferramenta foram desenvolvidos pelo Facebook. Ela foi lançada em 2013 como uma ferramenta JavaScript de código aberto. Atualmente, ela permanece na frente das suas principais competidoras, como a Angular e a Bootstrap, as duas bibliotecas JavaScript mais bem vendidas.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://www.figma.com/figjam/',
  key: 'figjam',
  id: 'figjam',
  title: 'Figjam',
  quadrant: 'frameworks',
  description:
    'O FigJam é um quadro branco online feito para designers idealizarem e discutirem ideações no espaço colaborativo da sua empresa, seja esse espaço remoto ou físico. É como uma versão leve do Figma que é mais fácil de aprender e ainda mais divertida de usar.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://clickup.com',
  key: 'clickup',
  id: 'clickup',
  title: 'ClickUp',
  quadrant: 'frameworks',
  description:
    'Se você procura um gerenciador de tarefas com altíssima performance e diversas funcionalidades, você vai se surpreender com o ClickUp! \n\nEle é o “queridinho” entre as grandes Startups do Brasil, é uma plataforma de trabalho baseada em nuvem para todos os tipos e tamanhos de equipes e empresas. Ele combina aplicativos de negócios importantes e centraliza as informações da empresa em uma única solução online. Atribua tarefas a membros da equipe, gerencie projetos para clientes e colabore com colegas em documentos. O ClickUp fornece todas as ferramentas e recursos para concluir o trabalho de forma eficiente, visível e acessível.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://angular.io',
  key: 'Angular',
  id: 'Angular',
  title: 'Angular',
  quadrant: 'frameworks',
  description:
    'Angular é uma plataforma e framework para construção da interface de aplicações usando HTML, CSS e, principalmente, JavaScript, criada pelos desenvolvedores da Google. Ele possui alguns elementos básicos que tornam essa construção interessante. Dentre os principais, podemos destacar os componentes, templates, diretivas, roteamento, módulos, serviços, injeção de dependências e ferramentas de infraestrutura que automatizam tarefas, como a de executar os testes unitários de uma aplicação. Angular nos ajuda a criar Single-Page Applications com uma qualidade e produtividade surpeendente! Alguns outros pontos dessa plataforma que merecem destaque são o fato de que ela é open source, possui uma grande comunidade, existem várias empresas utilizando e tem muito material de estudo para quem deseja se aperfeiçoar.'
});

// entries.push({
//   timeline: [
//     {
//       moved: 0,
//       ringId: 'adopt',
//       date: new Date('2020-08-06'),
//     },
//   ],
//   url: 'https://reactjs.org/',
//   key: 'AngularJs',
//   id: 'AngularJs',
//   title: 'AngularJs',
//   quadrant: 'frameworks',
// });

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://vuejs.org',
  key: 'VueJs',
  id: 'VueJs',
  title: 'VueJs',
  quadrant: 'frameworks',
  description:
    'É um framework JavaScript open source para a criação de aplicações web, criado por Evan You em 2014. O Vue.js possibilita criar aplicações de forma reativa. O Vue.js faz a utilização de um DOM virtual, o que faz com que seja extremamente performático na maioria das situações. Além disso, conta com uma arquitetura muito bem estruturada por meio da criação de componentes reusáveis. Este framework é muito utilizado para criar aplicações SPA (Single Page Applications) e também para desenvolver vários outros tipos de interfaces, com foco na interação e experiência do usuário. Atualmente e não à toa, o Vue.js está entre os frameworks Javascript para criação de interfaces mais populares do mundo, devido à sua baixa curva de aprendizado, sua versatilidade e por oferecer uma solução bem completa – contando até mesmo com uma CLI, o Vue CLI. Além disso, possui uma boa documentação oficial (inclusive em português) e sua comunidade é bem ativa e não para de crescer.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://expressjs.com',
  key: 'ExpressJs',
  id: 'ExpressJs',
  title: 'ExpressJs',
  quadrant: 'frameworks',
  description:
    'O Express.js é um Framework rápido e um dos mais utilizados em conjunto com o Node.js, facilitando no desenvolvimento de aplicações back-end e até, em conjunto com sistemas de templates, aplicações full-stack. Muito popular tanto em grandes empresas quanto na comunidade, o Express facilita a criação de aplicações utilizando o Node em conjunto com o JavaScript, tornando este ecossistema ainda mais poderoso.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/dotnet/api/?view=netframework-4.8&preserve-view=true',
  key: '.NET Framework',
  id: 'NETFramework',
  title: '.NET Framework',
  quadrant: 'frameworks',
  description:
    'O .NET Framework é um ambiente de execução criado pela Microsoft e gerenciado para Windows que oferece uma série de serviços voltados ao desenvolvimento web, reutilizando e reaproveitando códigos, entre suas principais funções. É um ambiente outsource  que possui componentes para a criação de códigos em determinadas linguagens, como C#, VB.NET e F#.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/dotnet/',
  key: '.NET Core',
  id: 'NETCore',
  title: '.NET Core',
  quadrant: 'frameworks',
  description:
    '.NET é uma plataforma de desenvolvedor multiplataforma de código aberto gratuita para criar muitos tipos de aplicativos. O .NET é criado em um runtime de alto desempenho que é usado em produção por muitos aplicativos de alta escala.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/dotnet/core/whats-new/dotnet-5',
  key: '.NET 5',
  id: 'NET5',
  title: '.NET 5',
  quadrant: 'frameworks',
  description:
    'NET 5, uma versão que unifica o . NET Framework com o . NET Core em uma única plataforma. Agora com apenas um ecossistema podemos direcionar o desenvolvimento de aplicações para Windows, MacOS, Linux, OS, Android, tvOS, watchOS e outras plataformas de uma forma mais transparente.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/dotnet/core/whats-new/dotnet-6',
  key: '.NET 6',
  id: 'NET6',
  title: '.NET 6',
  quadrant: 'frameworks',
  description:
    'NET 6 é uma versão de suporte de longo prazo (LTS) que terá suporte por três anos. É compatível com vários sistemas operacionais, incluindo macOS Apple Silicon e Windows Arm64. O . NET 6 oferece uma plataforma unificada para navegador, nuvem, desktop, IoT e aplicativos móveis. A plataforma  foi atualizada para atender às necessidades de todos os tipos de aplicativos e para facilitar a reutilização do código em todos os seus aplicativos. Novos recursos e melhorias estão disponíveis para todos os aplicativos ao mesmo tempo, para que seu código em execução na nuvem ou em um dispositivo móvel se comporte da mesma maneira e tenha os mesmos benefícios.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://codejourney.com.br/boas-praticas-para-code-review/',
  key: 'code-reviews',
  id: 'code-reviews',
  title: 'Code Reviews',
  quadrant: 'process',
  description:
    'Code Review é uma examinação sistemática de código-fonte computacional. Tem como intenção encontrar erros que passaram desapercebidos no desenvolvimento de software, melhorando a qualidade do software. Essas revisões são feitas de várias formas, como pair-programming, análises informais e até inspeções formais.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'double-diamond',
  id: 'double-diamond',
  title: 'Double Diamond',
  quadrant: 'process',
  description:
    'O Double Diamond é um método do Design Thinking criado pelo British Design Council, instituição sem fins lucrativos do Reino Unido. O objetivo desse método é mapear os estágios divergentes e convergentes dentro de um processo de design, com a intenção de encontrar uma solução real e assertiva em relação ao problema.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'research',
  id: 'research',
  title: 'Research',
  quadrant: 'process',
  // description:
  //   'falta aqui'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'squads',
  id: 'squads',
  title: 'Squads',
  quadrant: 'process',
  description:
    'A Squad tem propriedade sobre sua entrega, que pode ser um projeto, um produto, um serviço, um componente, etc. A Squad atua de forma autônoma, sendo responsável por garantir o atingimento de seus objetivos e gerar valor pros seus clientes.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'log-monitoring',
  id: 'log-moniroting',
  title: 'Log Monitoring',
  quadrant: 'process',
  description:
    'Os monitores de log são um tipo de software que monitora arquivos de log. Servidores, aplicativos, rede e dispositivos de segurança geram arquivos de log. Erros, problemas e mais informações são constantemente registrados e salvos para posterior análise de log.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'microservices',
  id: 'microservices',
  title: 'Code Like Microservices',
  quadrant: 'process',
  description:
    'Microsserviços são um tipo inovador de arquitetura de software, que consiste em construir aplicações desmembrando-as em serviços independentes. Estes serviços se comunicam entre si usando APIs e promovem grande agilidade em times de desenvolvimento.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'ci-cd',
  id: 'ci-id',
  title: 'CI-CD',
  quadrant: 'process',
  description:
    'Em engenharia de software, CI/CD ou CICD pode se referir às práticas combinadas de integração contínua e entrega contínua. CI/CD preenche as lacunas entre as atividades e equipes de desenvolvimento e operação, reforçando a automação na compilação, teste e implantação de aplicativos.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/azure/devops/repos/git/about-pull-requests?view=azure-devops',
  key: 'pull-requests',
  id: 'pull-requests',
  title: 'Pull Requests',
  quadrant: 'process',
  description:
    'As PRs (solicitações pull) são uma maneira de alterar, examinar e mesclar código em um repositório Git em Azure Repos. PRs podem vir de branches dentro do mesmo repositório ou de branches em bifurcações do repositório. As equipes usam PRs para examinar o código e fornecer comentários sobre as alterações antes de mesclar o código no branch principal. Os revisores podem percorrer as alterações propostas, deixar comentários e votar para aprovar ou rejeitar o código.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://www.alura.com.br/artigos/git-flow-o-que-e-como-quando-utilizar#:~:text=O%20que%20é%20Git%20Flow%3F,organização%20do%20versionamento%20de%20códigos.',
  key: 'git-flow',
  id: 'git-flow',
  title: 'Git Flow',
  quadrant: 'process',
  description:
    'O Git Flow é um modelo, uma estratégia ou, ainda, um fluxo de trabalho muito utilizado por equipes de desenvolvimento de software. Ele se destaca por auxiliar na organização do versionamento de códigos.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'docs-like-code',
  id: 'docs-like-code',
  title: 'Docs-like-code',
  quadrant: 'process',
  description:
    'O Docs like code (também conhecido como “Docs as code”) é basicamente uma abordagem semelhante à maneira como os engenheiros de software: escrevem código, constroem um executável, fazem o teste e depois publicam a entrega. \n Em termos de redação técnica, pode ser algo como: armazene sua fonte de conteúdo em um sistema de controle de versão como o Github (normalmente em formato como Markdown), use um gerador de site estático como Middleman, Gatsby, Hugo, Docusaurus, Jekyll, VuePress, MKDocs etc., produza um site de documentação, executando algumas verificações de validação (como links quebrados) e depois publique-o em seu provedor de hospedagem (Amazon ou qualquer outro de sua escolha).'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'open-architecture-decision',
  id: 'open-architecture-decision',
  title: 'Open Architecture Decision',
  quadrant: 'process',
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'compodoc',
  id: 'compodoc',
  title: 'Compodoc',
  quadrant: 'process',
  description:
    'Compodoc é um gerador de documentação desenvolvido para projetos .ts, para utilizar ele nós somente precisamos configurar o seu pacote para ler o nosso arquivo tsconfig.app.json. O Compodoc cria uma documentação completa do projeto, com ele nos temos registrado desde a estrutura do projeto contendo quantidade de arquivo para modules, components … etc até um link ver mais detalhes de cada um desses itens.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'whatsapp-planning',
  id: 'whatsapp-planning',
  title: 'Whatsapp Planning',
  quadrant: 'process',
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'two-factory-authentication',
  id: 'two-factory-authentication',
  title: 'Two Factory Authentication',
  quadrant: 'process',
  description:
    'A autenticação multifator é um método de autenticação eletrônico no qual um usuário tem acesso a um site ou aplicativo somente após apresentar com sucesso duas ou mais evidências para um mecanismo de autenticação: conhecimento, posse e herança.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'policy-password',
  id: 'policy-password',
  title: 'Policy Password',
  quadrant: 'process',
  description:
    'A política de senha é um conjunto de regras destinadas a aumentar a segurança de computadores, através do incentivo para os usuários utilizarem senhas fortes e usá-las corretamente.'
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'sprints',
  id: 'sprints',
  title: 'Sprints',
  quadrant: 'process',
  description:
    'Uma sprint é uma reunião de pessoas envolvidas num projeto para promover um desenvolvimento mais focalizado do projeto. O termo está fortemente relacionado ao framework de desenvolvimento ágil Scrum. Sprints normalmente têm duração de uma a quatro semanas.'
});

entries.push({
  timeline: [
    {
      ringId: 'hold',
      date: new Date('2020-08-06'),
    },
  ],
  url: '#',
  key: 'force-push',
  id: 'force-push',
  title: 'Force push to master',
  quadrant: 'process',
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://www.nginx.com',
  key: 'nginx',
  id: 'nginx',
  title: 'Nginx',
  quadrant: 'infrastructure',
  description:
    'NGINX, pronunciado “engine-ex,” é um famoso software de código aberto para servidores web lançado originalmente para navegação HTTP. Hoje, porém, ele também funciona como proxy reverso, balanceador de carga HTTP, e proxy de email para os protocolos IMAP, POP3, e SMTP. O NGINX foi lançado em Outubro de 2004. O criador do software, Igor Sysoev, começou o projeto em 2002 como uma resposta ao problema C10k. O C10k é o desafio de gerenciar 10 mil conexões ao mesmo tempo. Hoje em dia há ainda mais conexões que um servidor gerencia. Por este motivo o NGINX oferece uma arquitetura orientada a eventos e assíncrona, o que o torna um dos servidores mais confiáveis em questão de velocidade e escalabilidade. Devido a sua habilidade de suportar muitas conexões com alta velocidade, muitos sites de alto tráfego tem utilizado o NGINX. Alguns dos gigantes são Google, Netflix, Adobe, Cloudflare, WordPress.com, e muitos outros.'
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/azure/logic-apps/logic-apps-overview',
  key: 'azure-logic-apps',
  id: 'azure-logic-apps',
  title: 'Azure Logic Apps',
  quadrant: 'infrastructure',
  description:
    'Os Aplicativos Lógicos do Azure são uma plataforma de nuvem em que você pode criar e executar fluxos de trabalho automatizados com pouco ou nenhum código. Usando o designer visual e selecionando em operações predefinidas, você pode criar rapidamente um fluxo de trabalho que integra e gerencia seus aplicativos, dados, serviços e sistemas. Os Aplicativos Lógicos do Azure simplificam a maneira como você conecta sistemas herdados, modernos e de ponta em ambientes híbridos, locais e de nuvem e fornece ferramentas sem código baixo para desenvolver soluções de integração altamente escalonáveis para seus cenários B2B (empresa e negócios para empresas).'
});

entries.push({
  timeline: [
    {
      ringId: 'assess',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://grafana.com',
  key: 'grafana',
  id: 'grafana',
  title: 'Grafana',
  quadrant: 'infrastructure',
  description:
    'Grafana é uma aplicação web de análise de código aberto multiplataforma e visualização interativa da web. Ele fornece tabelas, gráficos e alertas para a Web quando conectado a fontes de dados suportadas. É expansível através de um sistema de plug-in.'
});

entries.push({
  timeline: [
    {
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/azure/azure-functions/',
  key: 'azure-functions',
  id: 'azure-functions',
  title: 'Azure Functions',
  quadrant: 'infrastructure',
  description:
    'O Azure Functions é um serviço de nuvem disponível sob demanda que fornece toda a infraestrutura e os recursos continuamente atualizados necessários para executar os aplicativos. Você se concentra nas partes do código mais importantes e o Functions cuida do restante. O Functions fornece computação sem servidor ao Azure. Você pode usar o Functions para criar APIs Web, responder a alterações no banco de dados, processar fluxos de IoT, gerenciar filas de mensagens, entre outras ações.'
});

entries.push({
  timeline: [
    {
      ringId: 'trial',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/azure/key-vault/general/',
  key: 'key-vault',
  id: 'key-vault',
  title: 'Key Vault',
  quadrant: 'infrastructure',
  description:
    'O Azure Key Vault ajuda a resolver os problemas a seguir: \nGerenciamento de Segredos – O Azure Key Vault pode ser usado para armazenar com segurança e controlar firmemente o acesso a tokens, senhas, certificados, chaves de API e outros segredos \nGerenciamento de Chaves – O Azure Key Vault pode ser usado como uma solução de gerenciamento de chaves. O Azure Key Vault torna fácil criar e controlar as chaves de criptografia usadas para criptografar seus dados. \n Gerenciamento de Certificado – O Azure Key Vault permite provisionar, gerenciar e implantar com facilidade certificados de protocolo TLS/SSL públicos e privados para uso com o Azure e seus recursos internos conectados.'
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://docs.docker.com/compose/',
  key: 'docker-compose',
  id: 'docker-compose',
  title: 'Docker Compose',
  quadrant: 'infrastructure',
  description:
    'O Docker Compose ajuda a definir e compartilhar aplicativos de vários contêineres. Com o Docker Compose, você pode criar um arquivo para definir os serviços. Com um único comando, você pode criar tudo ou derrubá-lo.'
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://learn.microsoft.com/pt-br/azure/storage/common/storage-introduction',
  key: 'azure-storage',
  id: 'azure-storage',
  title: 'Azure Blob Storage',
  quadrant: 'infrastructure',
  description:
    'O Armazenamento de Blobs do Azure é uma solução de armazenamento de objetos da Microsoft para a nuvem. O armazenamento de Blobs é otimizado para armazenar grandes quantidades de dados não estruturados, como texto ou dados binários.'
});

entries.push({
  timeline: [
    {
      ringId: 'assess',
      date: new Date('2020-08-06')
    },
  ],
  url: '#',
  key: 'waf',
  id: 'waf',
  title: 'Web Application Firewall',
  quadrant: 'infrastructure',
  description:
    'O WAF, ou firewall de aplicativos web, ajuda a proteger os aplicativos web ao filtrar e o monitorar o tráfego HTTP entre o aplicativo web e a internet. De modo geral, o WAF protege os aplicativos web contra ataques como falsificação de solicitação entre sites, cross-site-scripting (XSS), inclusão de arquivo e injeção de SQL, entre outros. O WAF é uma defesa de protocolo da camada 7 (no modelo OSI) e não foi desenvolvido para fins de defesa contra todos os tipos de ataques. Esse método de mitigação de ataques costuma fazer parte de um conjunto de ferramentas que, juntas, criam uma defesa holística contra diversos vetores de ataque.'
});

entries.push({
  timeline: [
    {
      ringId: 'trial',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://www.airbrake.io',
  key: 'Airbrake',
  id: 'Airbrake',
  title: 'Airbrake',
  quadrant: 'infrastructure',
  description:
    'O Airbrake fornece supervisão de aplicativos de pilha completa, alertando em tempo real sobre erros que afetam seus usuários. E a empresa fornece a você todo o contexto e diagnósticos de causa raiz que você precisa para corrigi-los rapidamente. Isso significa menos estresse para você e uma melhor experiência para seus usuários. O Airbrake suporta todas as principais plataformas e idiomas, integra-se às ferramentas de fluxo de trabalho existentes e é confiável por milhares de equipes de engenharia do mundo, incluindo Netflix, Salesforce, Oracle, eBay, Twitch e Soundcloud.'
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://www.microsoft.com/pt-br/sql-server/sql-server-downloads',
  key: 'sql-server',
  id: 'sql-server',
  title: 'Sql Server',
  quadrant: 'infrastructure',
  description: 
    'O SQL Server da Microsoft é um sistema especializado em gerenciar esses registros, funcionando como uma plataforma completa de soluções. Ele permite controlar a qualidade dos dados, desenvolver relatórios avançados e ainda é capaz de se integrar com diversas fontes. Tudo isso por meio de um sistema voltado a vários níveis de usuários.'
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://firebase.google.com/docs/hosting',
  key: 'firebase',
  id: 'firebase',
  title: 'Firebase',
  quadrant: 'infrastructure',
  description:
    'O Firebase Hosting é um recurso de hospedagem de conteúdo da Web de nível de produção para desenvolvedores. Com um único comando, é possível implantar apps da Web rapidamente e exibir conteúdo estático e dinâmico a uma rede de distribuição de conteúdo (CDN) global. Também é possível associar o Firebase Hosting ao Cloud Functions ou ao Cloud Run para criar e hospedar microsserviços no Firebase.'
});

entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://azure.microsoft.com/pt-br/products/cosmos-db/#features',
  key: 'cosmos-db',
  id: 'cosmos-db',
  title: 'Cosmos DB',
  quadrant: 'infrastructure',
  description:
    'O Azure Cosmos DB é um serviço de banco de dados de vários modelos, de propriedade da Microsoft, distribuído globalmente "para gerenciamento de dados em escala planetária", lançado em maio de 2017. É agnóstico em relação ao esquema, horizontalmente escalonável e geralmente classificado como um banco de dados NoSQL.'
});

entries.push({
  timeline: [
    {
      ringId: 'hold',
      date: new Date('2020-08-06')
    },
  ],
  url: 'https://www.mysql.com',
  key: 'mysql',
  id: 'mysql',
  title: 'MySQL',
  quadrant: 'infrastructure',
  description: 
    'O MySQL é um sistema de gerenciamento de banco de dados, que utiliza a linguagem SQL como interface. É atualmente um dos sistemas de gerenciamento de bancos de dados mais populares da Oracle Corporation, com mais de 10 milhões de instalações pelo mundo.'
});

export const mock = {
  entries,
  quadrants,
  rings,
};
export class SampleTechRadarApi  {
  async load() {
    return mock;
  }
}
