import React from 'react';
import { makeStyles, Grid, List, Paper } from '@material-ui/core';

import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import {
  catalogApiRef,
  CATALOG_FILTER_EXISTS,
} from '@backstage/plugin-catalog-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';

import { SearchType } from '@backstage/plugin-search';
import {
  DefaultResultListItem,
  SearchBar,
  SearchFilter,
  SearchResult,
  useSearch,
} from '@backstage/plugin-search-react';
import {
  CatalogIcon,
  Content,
  DocsIcon,
  Header,
  Page,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles((theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const SearchPage = () => {
  const classes = useStyles();
  const { types } = useSearch();
  const catalogApi = useApi(catalogApiRef);

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, { title: "Search",} )
      , React.createElement(Content, null
        , React.createElement(Grid, { container: true, direction: "row",}
          , React.createElement(Grid, { item: true, xs: 12,}
            , React.createElement(Paper, { className: classes.bar,}
              , React.createElement(SearchBar, null )
            )
          )
          , React.createElement(Grid, { item: true, xs: 3,}
            , React.createElement(SearchType.Accordion, {
              name: "Result Type" ,
              defaultValue: "software-catalog",
              types: [
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                  icon: React.createElement(CatalogIcon, null ),
                },
                {
                  value: 'techdocs',
                  name: 'Documentation',
                  icon: React.createElement(DocsIcon, null ),
                },
              ],}
            )
            , React.createElement(Paper, { className: classes.filters,}
              , types.includes('techdocs') && (
                React.createElement(SearchFilter.Select, {
                  className: classes.filter,
                  label: "Entity",
                  name: "name",
                  values: async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        'metadata.annotations.backstage.io/techdocs-ref':
                          CATALOG_FILTER_EXISTS,
                      },
                    });

                    const names = items.map(entity => entity.metadata.name);
                    names.sort();
                    return names;
                  },}
                )
              )
              , React.createElement(SearchFilter.Select, {
                className: classes.filter,
                label: "Kind",
                name: "kind",
                values: ['Component', 'Template'],}
              )
              , React.createElement(SearchFilter.Checkbox, {
                className: classes.filter,
                label: "Lifecycle",
                name: "lifecycle",
                values: ['experimental', 'production'],}
              )
            )
          )
          , React.createElement(Grid, { item: true, xs: 9,}
            , React.createElement(SearchResult, null
              , ({ results }) => (
                React.createElement(List, null
                  , results.map(({ type, document, highlight, rank }) => {
                    switch (type) {
                      case 'software-catalog':
                        return (
                          React.createElement(CatalogSearchResultListItem, {
                            key: document.location,
                            result: document,
                            highlight: highlight,
                            rank: rank,}
                          )
                        );
                      case 'techdocs':
                        return (
                          React.createElement(TechDocsSearchResultListItem, {
                            key: document.location,
                            result: document,
                            highlight: highlight,
                            rank: rank,}
                          )
                        );
                      default:
                        return (
                          React.createElement(DefaultResultListItem, {
                            key: document.location,
                            result: document,
                            highlight: highlight,
                            rank: rank,}
                          )
                        );
                    }
                  })
                )
              )
            )
          )
        )
      )
    )
  );
};

export const searchPage = React.createElement(SearchPage, null );
