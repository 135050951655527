
import {

    createTheme,
    genPageTheme,
    lightTheme,
    shapes,
  } from '@backstage/theme';

const myTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#005677',
    },
    secondary: {
      main: '#565a6e',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#000', '#343b58'], shape: shapes.wave }),
  },
});



const createCustomThemeOverrides = (
  theme,
) => {
  return {
    BackstageHeader: {
      header: {
        padding: theme.spacing(3),
        boxShadow: '0 0 8px 3px rgba(20, 20, 20, 0.3)',
        backgroundImage: "none",
        backgroundColor: '#005677'
      },
      title: {
        color: '#fff'
      },
      subtitle: {
        color: '#fff'
      }
    },
  };
};

export const customTheme = {
  ...lightTheme,
  ...myTheme,
  overrides: {
    ...lightTheme.overrides,
    ...createCustomThemeOverrides(lightTheme),
  },
};