import { Content, Header, Page, Progress } from '@backstage/core-components';
import { SearchBar, SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, Paper } from '@material-ui/core';
import React, { Component, } from 'react';
import ApplicationsService from '../../services/ApplicationsService';

import ApplicationsCard from './ApplicationsCard';






class ApplicationsPage extends Component {
    interval = 0;
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
            filtered: []
        };
    }

    componentDidMount() {
        this.getApplications();
        this.interval = setInterval(() => {
            this.getApplications();
        }, 6e4)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getApplications() {
        ApplicationsService.get()
            .then(res => {
                this.setState({
                    applications: this.sortByStatus(res.data),
                    filtered: this.sortByStatus(res.data),
                })
            })
            .catch((e) => {
                console.debug({ ...e });
                throw e.message;
            })
    }

    sortByStatus(arr) {
        return arr.sort((a) => a.lastStatus == 200 ? 1 : -1);
    }

    search(value) {
        if (!value) {
            this.setState({
                filtered: this.state.applications
            })
            return
        }
        if (value.length > 2) {
            this.setState({
                filtered: this.sortByStatus(this.state.applications).filter((el) => {
                    const val = value.toLowerCase(); 
                    return el.name.toLowerCase().match(val) || el.squad.toLowerCase().match(val) || el.projeto.toLowerCase().match(val)
                })
            })
        }
    }

    render() {
        if (this.state.applications.length == 0) {
            return React.createElement(Progress, null )
        }

        const preFiltered = {
            types: ['widget'],
            term: '',
            filters: {
            },
        };

        return (
            React.createElement(Page, { themeId: "home",}
                , React.createElement(Header, { title: "Applications", subtitle: "Aplicações da General Claims"   ,})
                , React.createElement(Content, null
                    , React.createElement(SearchContextProvider, { initialState: preFiltered,}
                        , React.createElement(Paper, { style: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px 0',
                            borderRadius: '5px',
                            margin: '15px 0px'
                        },}
                            , React.createElement(SearchBar, { clearButton: false, onChange: this.search.bind(this), placeholder: "Buscar aplicações" ,} )
                        )
                    )
                    , React.createElement(Wrapper, null
                        , 
                            this.state.filtered.map((data, index) => {
                                return (
                                    React.createElement(Grid, { item: true, xs: 3,}
                                        , React.createElement(ApplicationsCard, {
                                            ambiente: data.ambiente,
                                            estado: data.estado,
                                            lastStatus: data.lastStatus,
                                            name: data.name,
                                            ok: data.ok,
                                            projeto: data.projeto,
                                            rg: data.rg,
                                            subscricao: data.subscricao,
                                            squad: data.squad,
                                            key: index + 'appl',}
                                        )
                                    )
                                )
                            })
                        
                    )
                )
            )
        )
    }
}

const Wrapper = ({ children }) => (
    React.createElement(Grid, { container: true, spacing: 3,}
        , children
    )
);

export default (
    React.createElement(ApplicationsPage, null)
)