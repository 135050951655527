import React from 'react';
import { Page } from '@backstage/core-components';
import {
  TechDocsReaderPageHeader,
  TechDocsReaderPageSubheader,
  TechDocsReaderPageContent,
} from '@backstage/plugin-techdocs';

const DefaultTechDocsPage = () => {
  return (
    React.createElement(Page, { themeId: "documentation",}
      , React.createElement(TechDocsReaderPageHeader, null )
      , React.createElement(TechDocsReaderPageSubheader, null )
      , React.createElement(TechDocsReaderPageContent, null )
    )
  );
};

export const techDocsPage = React.createElement(DefaultTechDocsPage, null );