import http from '../http-common';


const get = () => {
    return http.get('/resource/get-applications');
};

const ApplicationsService = {
    get
};

export default ApplicationsService;